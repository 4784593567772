import ChatHeader from "./chatheader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faClose,
  faMessage,
  faMicrophone,
  faMicrophoneSlash,
  faPaperPlane,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import Chatloginform from "./chatloginform";
import './chat.scss';
import {
  langs,
  logPrefix,
  postIdleTimeout,
  sessionTimeout,
  TEST,
  URL,
  timestampToTime,
  showErrorMessageTimeout
} from "./tools";

const ChatPlugin = (props) => {

  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') === 'true');
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') === null
                                                          ? '' : localStorage.getItem('accessToken'));
  useEffect( () => {
//    console.log("useEffect - accessToken: ", accessToken);
    localStorage.setItem('accessToken', accessToken);
    setIsLoggedIn(!!accessToken);
  }, [accessToken]);
  const errorHandler = (error) => {
    console.log(logPrefix + "NETWORK ERROR");
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
    Logout();
  }
  const Logout = () => {
    setAccessToken('');
    localStorage.clear();
  }

  const [domains,setDomains] = useState(localStorage.getItem('domains') === null
                                        ? {} : JSON.parse(localStorage.getItem('domains')));

  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState("");
  const [processing, setProcessing] = useState(false);
  const [domain, setDomain] = useState(-1);
  const [person, setPerson] = useState("hu-HU-TamasNeural");
  const [detectedLang, setDetectedLang] = useState(navigator.language || navigator.userLanguage);
  const [listening, setListening] = useState(false);
  const [reading, setReading] = useState(false);
  const [blink, setBlink] = useState('');
  const [domainErrorMessage, setDomainErrorMessage] = useState(false);
  const [domainError, setDomainError] = useState(null);

  const getDomains = () => {
    return axios
      .get(URL,
        {
          headers: {
            "x-access-token": accessToken,
            "content-type": "application/json"
          },
          params: {
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          // console.log('Return axios getDomains');
          // console.log(JSON.stringify(response.data));
          localStorage.setItem('domains',JSON.stringify(response.data));
          setDomains(response.data);
          return response.data;
        } else {
          Logout();
          return null;
        }
      })
      .catch((error) => {
        errorHandler(error);
      });
  }
  const postIdle = () => {
    if(TEST) return;
    if(!localStorage.getItem('lastIdle'))
      localStorage.setItem('lastIdle', Date.now().toString());
    const elapsedTime = (Date.now() - parseInt(localStorage.getItem('lastIdle'), 10)) ;
    console.log(logPrefix + "last sent idle request: ", timestampToTime(elapsedTime) );

    if(elapsedTime > sessionTimeout) {
      Logout();
      return;
    }

    if(elapsedTime > postIdleTimeout ) {
      return axios
        .post(URL + "/idle",
          {},
          {
            headers: {
              "x-access-token": accessToken,
              "content-type": "application/json"
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            localStorage.setItem('lastIdle', Date.now().toString());
            console.log(logPrefix + 'Idle request sent');
            return response.data;
          } else {
            Logout();
            return null;
          }
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  }
  const getAnswer = (str, collection, email) => {
    if(TEST) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(`sample answer text to (knowledge base: ${domain} ): ${str}`);
        }, 2000);
      });
    }
    return axios
      .post(URL + (email ? '/email' : ''),
        {
          "collection": collection,
          "question": str,
        },
        {
          headers: {
            "x-access-token": accessToken,
            "content-type": "application/json"
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          console.log(logPrefix + 'Return axios');
          console.log(response.data)
          return response.data;
        } else {
          Logout();
          return null;
        }
      })
      .catch((error) => {
        errorHandler(error);
      });
  }


  const questionInput = useRef(0);
  const [showChatPopup, setShowChatPopup] = useState(false);
  const [showChatOptions, setShowChatOptions] = useState(true); //  when there isn't selected knowledge base
  const chatTitle = "";
  const [chatBody, setChatBody] = useState([]);
  const chatBodyRef = useRef();
  useEffect(() => {
    if(chatBody.length)
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
  }, [chatBody]);
  const addBody = (question, qTime, answer) => {
    const [hour, minutes,seconds] = new Date().toLocaleTimeString().slice(0, 8).split(':');
    setChatBody( [...chatBody,<div key={`${hour}${minutes}${seconds}`}>
      <div className={'question'}>
        <div className={'wrapper'}>
          <div className={'message'}>{question}</div>
        </div>
        <div className={'wrapper'}>
          <div className={'time'}>{qTime}</div>
        </div>
      </div>
      <div className={'answer'}>
        <div className={'wrapper'}>
          <div className={'message'}>{answer}</div>
        </div>
        <div className={'wrapper'}>
          <div className={'time'}>{hour}:{minutes}:{seconds}</div>
        </div>
      </div></div>]
    )
  }
  const sendChatQuestion = () => {
    console.log(logPrefix + 'Click to chat putQuestion button');
    if(domain === -1) {
      console.log(logPrefix + "Domain is not selected");
      setDomainError(true);
      setDomainErrorMessage(true);
      setShowChatOptions(true);
      setTimeout(() => {
        setDomainErrorMessage(false);
      }, showErrorMessageTimeout);

      return false;
    }
    if(question === '') {
      console.log(logPrefix + "Empty question");
      return false;
    }

    setQuestion(questionInput.current.value);
    if(question === null) // only the initial state is null
      return;

    const [hour, minutes, seconds] = new Date().toLocaleTimeString().slice(0, 8).split(':');
    setProcessing(true);
    getAnswer(question, domain,false).then(
      (res) => {
        setAnswer(res);
        addBody(question,`${hour}:${minutes}:${seconds}`,res);
        setQuestion('');
        setShowChatOptions(false);
        setProcessing(false);
      }
    );
  }

  if(isLoggedIn && Object.keys(domains).length === 0)
    getDomains();

  return <>
    <div className={"chat-plugin"} onClick={postIdle}>
      <div className={"chat-popup " + (showChatPopup ? "show" : "hide")}>
        {!isLoggedIn && <>
          <ChatHeader
            chatTitle={chatTitle}
            showChatOptions={false}
            setShowChatOptions={() => {}}
            showChatPopup={setShowChatPopup}
            setShowChatPopup={setShowChatPopup}
            showChatOptionsButton={false}
            processing={processing}
          />
          <Chatloginform token={setAccessToken}
                         url={URL}
                         setProcessing={setProcessing}
                         processing={processing} />
        </>}
        {isLoggedIn && <>
          <ChatHeader
            chatTitle={chatTitle}
            showChatOptions={showChatOptions}
            setShowChatOptions={setShowChatOptions}
            showChatPopup={setShowChatPopup}
            setShowChatPopup={setShowChatPopup}
            showChatOptionsButton={true}
            processing={processing}
          />
          <div className={"chat-options " + (showChatOptions ? "show" : "hide")}>
            <label className={"label-select-domain"}>
              <div>Knowledge base:</div>
              <select className={"select-domain"}
                      onChange={(e) => {
                        postIdle();
                        setDomain(e.target.value)
                      }}
                      defaultValue={"6"}
              >
                <option value="-1">Choose knowledge base</option>
                {Object.keys(domains).length > 0 && Object.entries(domains).map(([key, value], i) =>
                  <option value={value} key={i}>{value}</option>
                )}
              </select>
            </label>
            <label className={"label-select-person"}>
              <div>Recognition language / Reading</div>
              <select className={"select-person"}
                      onChange={(e) => {
                        setPerson(e.target.value)
                      }}
                      value={person !== '' ? person : 'hu-HU-TamasNeural'}
              >
                <option value="autodetect">Autodetect language</option>
                {Object.entries(langs).map((lang, i) =>
                  <option value={lang[1].person} key={lang[1].code + '-' + i}>
                    {lang[1].code} / {lang[1].person} ({lang[1].gender})
                  </option>
                )}
              </select>
            </label>
            <div className={'button-container'}>
            <button className={"logout"}
                    onClick={Logout}
            >Logout</button>
            <button className={"clear-history"}
                    onClick={() => {setChatBody([])}}
            >Clear history</button>
            <button className={"close"}
                    onClick={() => {
                      setShowChatOptions(!showChatOptions)
                    }}
            >Hide options</button>
            </div>
          </div>
          <div className={"body"} ref={chatBodyRef}>
            {(!chatBody.length ?
              <div className={"init"}>
                Ask! Please type your question or use the microphone button to recognize your speech</div>
              : chatBody)}
          </div>
          <div className={"footer"}>
              <textarea ref={questionInput}
                        className={"question " + blink}
                        rows={3}
                        value={!!question ? question : ''}
                        onChange={(e) => {
                          postIdle();
                          setQuestion((question.length > 0 && listening ? question + '\n' : '') + e.target.value)
                        }}
              />
            <div className={"buttons"}>
              <FontAwesomeIcon icon={faTrash}
                               className={"clear"}
                               onClick={() => {
                                 console.log(logPrefix + 'Click to Clear button');
                                 setQuestion('');
                               }}
              />
              <FontAwesomeIcon icon={listening ? faMicrophone : faMicrophoneSlash}
                               className={"listen " + (listening ? '_ing' : '')}
                               onClick={() => {
                                 console.log(logPrefix + 'Click to Listen button');
                                 setListening(!listening);
                               }}
              />
              <FontAwesomeIcon icon={faPaperPlane}
                               className={"putQuestion"}
                               disabled={question === ''}
                               onClick={() => {
                                 sendChatQuestion();
                               }}
              />
            </div>
          </div>
          <div className={"message error" + (domainErrorMessage ? "" : " invisible")}>
            <p>Choose a knowledge base before submitting a question</p>
            <FontAwesomeIcon
              icon={faClose}
              className={"close-error-message"}
              onClick={() => {
                setDomainErrorMessage(false)
              }}
            />

          </div>
        </>}
      </div>
      <div className={"chat-button " + (showChatPopup ? "close" : "open")}
           onClick={() => {
             setShowChatPopup(!showChatPopup)
           }}>
        <FontAwesomeIcon
          icon={(showChatPopup ? faClose : faMessage)}
          className={"icon"}
        />
      </div>
    </div>
  </>;
}
export default ChatPlugin;
