export const TEST = false;
export const langs = {
  0: {'code':"de-DE", 'person': "de-DE-AmalaNeural", 'gender': "Female"},
  1: {'code':"de-DE", 'person': "de-DE-BerndNeural", 'gender': "Male"},
  2: {'code':"en-US", 'person': "en-US-AshleyNeural", 'gender': "Female"},
  3: {'code':"en-US", 'person': "en-US-BrandonNeural", 'gender': "Male"},
  4: {'code':"es-ES", 'person': "es-ES-LiaNeural", 'gender': "Female"},
  5: {'code':"es-ES", 'person': "es-ES-AlvaroNeural", 'gender': "Male"},
  6: {'code':"fr-FR", 'person': "fr-FR-BrigitteNeural", 'gender': "Female"},
  7: {'code':"fr-FR", 'person': "fr-FR-AlainNeural", 'gender': "Male"},
  8: {'code':"hu-HU", 'person': "hu-HU-NoemiNeural", 'gender': "Female"},
  9: {'code':"hu-HU", 'person': "hu-HU-TamasNeural", 'gender': "Male"},
  10: {'code':"it-IT", 'person': "it-IT-ElsaNeural", 'gender': "Female"},
  11: {'code':"it-IT", 'person': "it-IT-DiegoNeural1", 'gender': "Male"},
};
export const URL = "https://cheppy.westeurope.cloudapp.azure.com";
export const postIdleTimeout = 60000; //  milliseconds (1m)
export const sessionTimeout = 3600000; //  milliseconds (1h)
export const showErrorMessageTimeout = 4000; //  milliseconds (4s)
export const logPrefix = "[AI-Desk chat] ";
export function timestampToTime(timestamp) {
  // Create a new Date object from the timestamp (in milliseconds)
  const date = new Date(timestamp);

  // Extract hours, minutes, and seconds
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');

  // Return the time in HH:MM:SS format
  return `${hours}:${minutes}:${seconds}`;
}
